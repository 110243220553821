import axios from 'axios';

// const ip = 'http://' + window.location.hostname;
const ip = 'http://139.59.59.120';

const BASE_URL = ip + ":5000";
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token');
  if (token) {
    config.headers['access-token'] = token;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response && error.response.status === 401) {
    sessionStorage.clear();
    window.location.href = '/';
  }
  return Promise.reject(error);
});

export const login = (data) => {
  return axiosInstance.post('/api/users/login', data);
};

export const getDashboardCount = () => {
  return axiosInstance.get('/api/dashboard/getdashboardcount');
};

// Banners API
export const getBannersApi = () => {
  return axiosInstance.get('/api/banners');
};

export const getImageApi = (data) => {
  return BASE_URL + data;
};

export const addBannerApi = (data) => {
  const headers = {
    'Content-Type': data instanceof FormData ? 'multipart/form-data' : 'application/json',
  };
  return axiosInstance.post('/api/banners/add', data, { headers });
};

export const updateBannerApi = (data) => {
  return axiosInstance.put(`/api/banners/${data.id}`, data);
};

export const deleteBannerApi = (id) => {
  return axiosInstance.delete(`/api/banners/${id}`);
};

// Categories API
export const getCategoriesApi = () => {
  return axiosInstance.get('/api/categories');
};

export const addCategoryApi = (data) => {
  const headers = {
    'Content-Type': data instanceof FormData ? 'multipart/form-data' : 'application/json',
  };
  return axiosInstance.post('/api/categories/add', data, { headers });
};

export const updateCategoryApi = (data) => {
  return axiosInstance.put(`/api/categories/${data.id}`, data);
};

export const deleteCategoryApi = (id) => {
  return axiosInstance.delete(`/api/categories/${id}`);
};

// Products API
export const getProductsApi = () => {
  return axiosInstance.get('/api/products');
};

export const addProductApi = (data) => {
  const headers = {
    'Content-Type': data instanceof FormData ? 'multipart/form-data' : 'application/json',
  };
  return axiosInstance.post('/api/products/add', data, { headers });
};

export const updateProductApi = (data) => {
  return axiosInstance.put(`/api/products/${data.id}`, data);
};

export const deleteProductApi = (id) => {
  return axiosInstance.delete(`/api/products/${id}`);
};

// Orders API
export const getOrdersApi = () => {
  return axiosInstance.get('/api/orders');
};

export const addOrderApi = (data) => {
  return axiosInstance.post('/api/orders/add', data);
};

export const updateOrderApi = (data) => {
  return axiosInstance.put(`/api/orders/update`, data);
};

export const deleteOrderApi = (id) => {
  return axiosInstance.delete(`/api/orders/${id}`);
};
// Fetch all users
export const getUsersApi = () => {
  return axiosInstance.get('/api/users');
};

// Create a new user
export const createUserApi = (data) => {
  return axiosInstance.post('/api/users/add', data);
};

// Update an existing user
export const updateUserApi = (data) => {
  return axiosInstance.put(`/api/users/${data.user_id}`, data);
};

// Delete a user by ID
export const deleteUserApi = (userId) => {
  return axiosInstance.delete(`/api/users/${userId}`);
};

// Fetch all pincodes
export const getPincodesApi = () => {
  return axiosInstance.get('/api/pincodes');
};

// Create a new pincode
export const addPincodeApi = (data) => {
  return axiosInstance.post('/api/pincodes/add', data);
};

// Update an existing pincode
export const updatePincodeApi = (data) => {
  return axiosInstance.put(`/api/pincodes/update`, data);
};

// Delete a pincode by ID
export const deletePincodeApi = (pincodeId) => {
  return axiosInstance.delete(`/api/pincodes/${pincodeId}`);
};

// Get all subscriptions
export const getSubscriptionsApi = (data) => {
  return axiosInstance.post('/api/subscriptions',data);
};

// Add new subscription
export const addSubscriptionApi = (data) => {
  return axiosInstance.post('/api/subscriptions/add', data);
};

// Update subscription by ID
export const updateSubscriptionApi = (data) => {
  return axiosInstance.put(`/api/subscriptions/update`, data);
};

// Delete subscription by ID
export const deleteSubscriptionApi = (id) => {
  return axiosInstance.delete(`/api/subscriptions/${id}`);
};